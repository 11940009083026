import { makeAutoObservable } from 'mobx';
import { CircuitModel, CircuitType } from './CircuitModel';
import { LogicCircuitModel } from './LogicCircuitModel';
import { StructureCircuitModel } from './StructureCircuitModel';
import { ChipInstanceModel } from './ChipInstanceModel';
import { createChipId } from '../types/ids';
import { AppModel } from './AppModel';
import { Point } from './Point';

/** Model of Chip that will be reused */
export class ChipModel {
  id = createChipId();

  isMain = false;
  /** data that will be copied to create new ChipInstances */
  editingInstance: ChipInstanceModel;
  logicCircuit = new LogicCircuitModel(new CircuitModel(CircuitType.ChipLogic));
  structureCircuit = new StructureCircuitModel(
    new CircuitModel(CircuitType.ChipStructure),
  );

  constructor(private appModel: AppModel) {
    this.editingInstance = new ChipInstanceModel(appModel, this.id, new Point(0, 0));
    makeAutoObservable(this, {}, { autoBind: true });
  }
}
