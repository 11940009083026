import { makeAutoObservable } from 'mobx';
import { ChipStoreModel } from './ChipStoreModel';
import { ChipId, ChipInstanceId } from '../types/ids';
import { ChipInstanceModel } from './ChipInstanceModel';
import { Wap } from '../utils/Wap/Wap';

export class ProjectModel {
  chipInstanceMap: Wap<ChipInstanceId, ChipInstanceModel> = new Wap();
  associatedChipIds: ChipId[] = [];

  get associatedChips() {
    return this.associatedChipIds.map((chipId) => this.chipStoreModel.getChip(chipId));
  }

  constructor(
    private chipStoreModel: ChipStoreModel,
    public name: string,
    public mainChipId: ChipId,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get mainChip() {
    const mainChip = this.chipStoreModel.getChip(this.mainChipId);

    if (!mainChip) {
      throw new Error(`Main chip not found: ${this.mainChipId}`);
    }

    return mainChip;
  }
}
