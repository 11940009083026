import { makeAutoObservable } from 'mobx';
import { NodeId } from '../types/ids';
import { SimNode, SimNodeDto } from './SimulationModel';
import { EventBus } from './EventBus';

export interface LogicGraphDto {
  simNodeDtos: SimNodeDto[];
}

export class LogicGraph {
  nodeMap = new Map<NodeId, SimNode>();

  static fromDto(dto: LogicGraphDto, eventBus: EventBus) {
    const logicGraph = new LogicGraph();

    for (const nodeDto of dto.simNodeDtos) {
      const simNode = SimNode.fromDto(nodeDto, eventBus);
      logicGraph.nodeMap.set(simNode.id, simNode);
    }

    return logicGraph;
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get dto(): LogicGraphDto {
    const simNodeDtos = Array.from(this.nodeMap.values()).map((node) => node.dto);

    return {
      simNodeDtos,
    };
  }

  clone(eventBus: EventBus, copyId = false) {
    const logicGraph = new LogicGraph();

    for (const [nodeId, node] of this.nodeMap) {
      logicGraph.nodeMap.set(nodeId, node.clone(eventBus, copyId));
    }

    return logicGraph;
  }
}
