import { makeAutoObservable } from 'mobx';
import { ChipId, ChipInstanceId, createChipInstanceId } from '../types/ids';
import { AppModel } from './AppModel';
import { Point, PointData } from './Point';
import { LogicGraph, LogicGraphDto } from './LogicGraph';
import { globalEventBus } from './EventBus';

export interface ChipInstanceDto {
  id: ChipInstanceId;
  chipId: ChipId;
  originPointDto: PointData;
  logicGraphDto: LogicGraphDto;
}

/** Chip instance model copied for each new chip instance mainly for the logicGraph */
export class ChipInstanceModel {
  id = createChipInstanceId();
  logicGraph: LogicGraph = new LogicGraph();

  static fromDto(appModel: AppModel, dto: ChipInstanceDto) {
    const chipInstanceModel = new ChipInstanceModel(
      appModel,
      dto.chipId,
      Point.fromDto(dto.originPointDto),
    );
    chipInstanceModel.id = dto.id;
    chipInstanceModel.logicGraph = LogicGraph.fromDto(dto.logicGraphDto, globalEventBus);

    return chipInstanceModel;
  }

  constructor(
    private appModel: AppModel,
    public chipId: ChipId,
    public originPoint: Point,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get chip() {
    return this.appModel.chipStoreModel.getChip(this.chipId);
  }

  get dto(): ChipInstanceDto {
    return {
      id: this.id,
      chipId: this.chipId,
      originPointDto: this.originPoint.data,
      logicGraphDto: this.logicGraph.dto,
    };
  }
}
