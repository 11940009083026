import { createNodeId, NodeId } from '../types/ids';
import { Wap } from '../utils/Wap/Wap';
import { ChipInstanceModel } from './ChipInstanceModel';
import { EditorModel } from './EditorModel';
import { EventBus, globalEventBus } from './EventBus';
import { LogicCircuitModel } from './LogicCircuitModel';
import { LogicGraph } from './LogicGraph';
import { Side } from './tiles/TileDefinition';
import { TileInstance, TileInstanceDto } from './tiles/TileInstance';

const setAllNodeConnections: (simNode: SimNode | null) => Record<Side, SimNode | null> = (
  node,
) => ({
  [Side.BOTTOM]: node,
  [Side.LEFT]: node,
  [Side.TOP]: node,
  [Side.RIGHT]: node,

  [Side.INSIDE]: node,
});

const defaultNodeConnections: () => Record<Side, SimNode | null> = () =>
  setAllNodeConnections(null);

export class SimulationModel {
  nodeMap = new Wap<NodeId, SimNode>();

  eventBus = globalEventBus;

  constructor(public editorModel: EditorModel, mainLogicCircuit: LogicCircuitModel) {
    // this.connectGraph(null, mainLogicCircuit);
  }

  addSimNode(simNode: SimNode) {
    this.nodeMap.set(simNode.id, simNode);
  }

  connectGraph(logicGraph: LogicGraph, chipInstance: ChipInstanceModel) {
    // TODO: Implement
    throw new Error('Not implemented');
  }

  disconnectGraph(logicGraph: LogicGraph, chipInstance: ChipInstanceModel) {
    // TODO: Implement
    throw new Error('Not implemented');
  }

  simulate(logicGraph: LogicGraph) {
    // TODO: Implement
    throw new Error('Not implemented');
  }
}

export interface SimNodeDto {
  id: NodeId;
  tileInstanceDto: TileInstanceDto;
  outputs: Record<Side, SimNode | null>;
  inputs: Record<Side, SimNode | null>;
}

export class SimNode {
  id: NodeId = createNodeId();

  outputs: Record<Side, SimNode | null> = defaultNodeConnections();
  inputs: Record<Side, SimNode | null> = defaultNodeConnections();

  static fromDto(dto: SimNodeDto, eventBus: EventBus) {
    const simNode = new SimNode(TileInstance.fromDto(dto.tileInstanceDto, eventBus));

    simNode.id = dto.id;
    simNode.outputs = { ...dto.outputs };
    simNode.inputs = { ...dto.inputs };

    return simNode;
  }

  constructor(public tileInstance: TileInstance) {}

  get dto(): SimNodeDto {
    return {
      id: this.id,
      tileInstanceDto: this.tileInstance.dto,
      outputs: this.outputs,
      inputs: this.inputs,
    };
  }

  clone(eventBus: EventBus, copyId = false) {
    const copy = SimNode.fromDto(this.dto, eventBus);

    if (!copyId) {
      copy.id = createNodeId();
    }

    return copy;
  }
}
