import { v4 as uuidv4 } from 'uuid';

export const createIdGenerator = () => {
  return () => uuidv4();
};

export const idGenerator = createIdGenerator();

export type ChipId = string & { _type: 'ChipId' };

export const isChipId = (id: string): id is ChipId => {
  return id.startsWith('chip-');
};

export const createChipId = (): ChipId => {
  return `chip-${idGenerator()}` as ChipId;
};

export type TileId = string & { _type: 'TileId' };

export const isTileId = (id: string): id is TileId => {
  return id.startsWith('tile-');
};

export const createTileId = (): TileId => {
  return `tile-${idGenerator()}` as TileId;
};

export type ChipInstanceId = string & { _type: 'ChipInstanceId' };

export const isChipInstanceId = (id: string): id is ChipInstanceId => {
  return id.startsWith('instance-');
};

export const createChipInstanceId = (): ChipInstanceId => {
  return `instance-${idGenerator()}` as ChipInstanceId;
};

export type CircuitId = string & { _type: 'CircuitId' };

export const isCircuitId = (id: string): id is CircuitId => {
  return id.startsWith('circuit-');
};

export const createCircuitId = (): CircuitId => {
  return `circuit-${idGenerator()}` as CircuitId;
};

export type NodeId = string & { _type: 'NodeId' };

export const isNodeId = (id: string): id is NodeId => {
  return id.startsWith('node-');
};

export const createNodeId = (): NodeId => {
  return `node-${idGenerator()}` as NodeId;
};
