import { makeAutoObservable } from 'mobx';
import { TileManagerDto, TileManagerModel } from './TileManagerModel';
import { AppModel } from './AppModel';
import { CircuitId, createCircuitId } from '../types/ids';
import { globalEventBus } from './EventBus';

export enum CircuitType {
  Main = 'Main',
  ChipLogic = 'ChipLogic',
  ChipStructure = 'ChipStructure',
}

export interface CircuitDto {
  id: CircuitId;
  circuitType: CircuitType;
  tileManagerDto: TileManagerDto;
}

export class CircuitModel {
  id = createCircuitId();

  tileManagerModel = new TileManagerModel(globalEventBus);

  static fromDto(dto: CircuitDto, appModel: AppModel, useNewId = false) {
    const model = new CircuitModel(dto.circuitType);
    if (!useNewId) {
      model.id = dto.id;
    }
    model.tileManagerModel = TileManagerModel.fromDto(dto.tileManagerDto, globalEventBus);
    return model;
  }

  constructor(public circuitType: CircuitType) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get tiles() {
    return this.tileManagerModel.tiles;
  }

  get dto() {
    return {
      id: this.id,
      tileManagerModel: this.tileManagerModel.dto,
    };
  }
}
